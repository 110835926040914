@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  display: block;
  height: 50px;
  min-height: 50px;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  width: 100%;
  .gnav-offers-hidden & {
    display: none;
    height: 0;
    min-height: 0;
  }
  &__carousel {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    @include breakpoint($medium-up) {
      padding: 0 80px;
    }
    &-item {
      display: none;
      &.item-active {
        display: block;
      }
      .fade & {
        animation-name: fade;
        animation-duration: 1.5s;
      }
    }
    &-btn {
      a,
      button {
        background: 0 0;
        border: none;
        height: 15px;
        position: absolute;
        width: 15px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
      }
      .icon {
        color: $color-white;
        fill: $color-white;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
      }
    }
  }
  &__arrow-prev {
    display: none;
    #{$ldirection}: 20px;
    @include breakpoint($large-up) {
      display: block;
    }
    .icon--dropdown {
      #{$ldirection}: 0;
      text-align: $ldirection;
      transform: rotate(90deg);
    }
  }
  &__arrow-next {
    display: none;
    #{$rdirection}: 20px;
    @include breakpoint($large-up) {
      display: block;
      #{$rdirection}: 55px;
    }
    .icon--dropdown {
      #{$rdirection}: 0;
      text-align: $rdirection;
      transform: rotate(-90deg);
    }
  }
  a {
    color: $color-white;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$rdirection}: 0;
    @include breakpoint($large-up) {
      #{$rdirection}: 20px;
    }
    a,
    svg {
      #{$rdirection}: 0;
    }
    .icon {
      fill: $color-white;
      height: 12px;
      width: 12px;
    }
  }
  &__btn-close {
    #{$rdirection}: 0;
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

